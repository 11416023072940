const domains = {
  local: "http://localhost:3000/",
  dev: "https://api.dev.locationservices.us.idemia.io/dev/",
  "ea-3991": "https://api.dev.locationservices.us.idemia.io/ea-3991/",
  test: "https://api.test.locationservices.us.idemia.io/test/",
};

export function getDomain() {
  if (process.env.REACT_APP_ENVIRONMENT) {
    let domain = process.env.REACT_APP_ENVIRONMENT;
    switch (domain) {
      case "local":
        return domains.local;
      case "ea-3991":
        return domains["ea-3991"];
      case "dev":
        return domains.dev;
      case "test":
        return domains.test;
      default:
        return domain;
    }
  } else {
    throw Error("REACT_APP_ENVIRONMENT not set.");
  }
}
