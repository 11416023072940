import { React, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useOktaAuth } from "@okta/okta-react";
import WarningError from "./WarningError";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getDomain } from "./Domain";
import { useHistory } from "react-router-dom";
import { responseHandler } from "./utilities/HttpResponseHandler";
import FormValidation from "./FormValidation";
import { messages } from "./Constants/Message";
import SubHeader from "./components/SubHeader";
import CustomDialog from "./components/CustomDialog";
import CustomAlert from "./components/CustomAlert";
import NavButtons from "./components/NavButtons";
import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import ReviewContent from "./Review/ReviewContent";
import { getInfoFromLocationVersion } from "./utilities/LocationUtil";
import ClosureReviewContent from "./Review/ClosureReviewContent";
import ClosureModal from "./Closure/ClosureModal";
import { stateArray } from "./stateArray";
import CancelClosureModal from "./Closure/CancelClosureModal";

const useStyles = makeStyles(theme => ({
  sectionContainer: {
    marginBottom: "15px",
    "&:last-child": {
      marginBottom: "0"
    }
  },
  sectionHeader: {
    marginBottom: "15px",
    marginTop: "15px"
  },
  sectionColumn: {
    marginRight: "45px",
    "&:last-child": {
      marginRight: "0"
    }
  },
  inputRow: {
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0"
    }
  },
  inputColumn: {
    marginRight: "11px",
    "&:last-child": {
      marginRight: "0"
    }
  },
  formControl: {
    width: "100%"
  },
  moreDetailInput: {
    width: "100%",
    height: "calc(100% - 8px)",
    "&>div": {
      height: "100%"
    }
  },
  closeLocationButton: {
    width: "200px"
  },
  addFutureVersionButton: {
    width: "200px"
  },
  returnToHomepageButton: {
    width: "200px"
  },
  locationManagementButtons: {
    marginLeft: "auto",
    alignItems: "center"
  },
  itemHeader: {
    marginBottom: "1px",
    fontWeight: "600",
    fontSize: "14px"
  },
  item: {
    color: "#676767"
  }
}));

const EditLocationHome = props => {
  const classes = useStyles();
  const searchParams = new URLSearchParams(window.location.search);
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const [locationObj, setLocationObj] = useState(null);
  const [selectedTab, setSelectedTab] = useState("current");
  const [loading, setLoading] = useState("Loading.....");

  const [common, setCommon] = useState(null);
  const [closureLocation, setClosureLocation] = useState(null);

  const [dirtyState, setDirtyState] = useState({
    locationName: false,
    bssrid: false,
    description: false
  });

  const [error, setError] = useState("");
  const [alertMessage, setAlertMessage] = useState(
    searchParams.get("state") === "canceled"
      ? "You have successfully closed your location"
      : searchParams.get("state") === "edited"
      ? "You have successfully updated your location"
      : searchParams.get("state") === "deleted"
      ? "You have successfully deleted your location"
      : ''
  );
  const [showClosure, setShowClosure] = useState(false);
  const [showCancelClosureModal, setShowCancelClosureModal] = useState(null);
  const [closureModalMode, setClosureModalMode] = useState(null);

  let history = useHistory();

  useEffect(() => {
    setLoading("Loading.....");
    fetch(
      `${getDomain()}tenants/UE/location/${props.match.params.locationId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
      .then(response => {
        return responseHandler(response);
      })
      .then(object => {
        setLocationObj(object);
        setSelectedTab(object.currentVersion ? "current" : "future");
        setLoading("");
      })
      .catch(e => {
        if (
          e.message === "404" ||
          e.message === "4xx" ||
          e.message === "5xx" ||
          e.message === "non-200"
        ) {
          console.error("error message");
        } else {
          setError("timeout");
        }
        setLoading("");
      });
    return () => {};
  }, []);

  useEffect(() => {
    if (locationObj) {
      setCommon(
        locationObj.currentVersion
          ? locationObj.currentVersion
          : locationObj.futureVersion
      );
      setClosureLocation(
        locationObj.futureVersion && locationObj.futureVersion.closed
          ? locationObj.futureVersion
          : locationObj.currentVersion
      );
    }
  }, [locationObj]);

  const textFieldHandler = e => {
    setCommon({
      ...common,
      [e.target.name]: e.target.value
    });
    setDirtyState({
      ...dirtyState,
      [e.target.name]: true
    });
  };

  const handleCommonSave = () => {
    setLoading("Saving.....");

    fetch(`${getDomain()}tenants/UE/location/${common.locationId}`, {
      method: "put",
      body: JSON.stringify({
        name: common.locationName,
        bssrid: common.bssrid === "" ? null : common.bssrid,
        description: common.description,
        salesTaxRate: parseFloat(common.salesTaxRate)
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        return responseHandler(response);
      })
      .then(object => {
        if (locationObj.futureVersion && locationObj.currentVersion) {
          setLocationObj({
            ...locationObj,
            futureVersion: {
              ...locationObj.futureVersion,
              bssrid: common.bssrid,
              locationName: common.locationName,
              description: common.description
            },
            currentVersion: {
              ...locationObj.currentVersion,
              bssrid: common.bssrid,
              locationName: common.locationName,
              description: common.description
            }
          });
        }
        else if (locationObj.futureVersion) {
          setLocationObj({
            ...locationObj,
            futureVersion: {
              ...locationObj.futureVersion,
              bssrid: common.bssrid,
              locationName: common.locationName,
              description: common.description
            }
          });
        }

        else if (locationObj.currentVersion) {
          setLocationObj({
            ...locationObj,
            currentVersion: {
              ...locationObj.currentVersion,
              bssrid: common.bssrid
            }
          });
        }
        setAlertMessage("A location is successfully updated");
        setLoading("");
      })
      .catch(error => {
        setAlertMessage(messages.serverError);
        setLoading("");
      });
  };

  function isFormValid(checkClean, location, dirtyValues) {
    return Object.keys(location).reduce((prev, next) => {
      if (["locationName", "bssrid", "description"].find(val => val === next)) {
        const hasError = !FormValidation[next](location[next]);

        if (checkClean) {
          return prev && !hasError;
        } else {
          const isDirty = dirtyValues[next];
          if (isDirty) {
            return prev && !hasError;
          } else {
            return prev && true;
          }
        }
      } else {
        return prev;
      }
    }, true);
  }

  const addFutureVersionHandler = e => {
    history.push({
      pathname: `/AddFutureLocation/${common.locationId}`,
      state: {
        common: {
          ...common,
          effectiveDate: null
        }
      }
    });
  };

  const editFutureVersionHandler = e => {
    history.push({
      pathname: `/editFutureLocation/${locationObj.futureVersion.locationName}`,
      state: {
        common: locationObj.futureVersion
      }
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box className="box-margin">
      <CustomDialog
        open={!!loading}
        header={loading}
        showCloseX={false}
        content={
          <CircularProgress size={36} className={classes.buttonProgress} />
        }
      />
      <SubHeader pageTitle="Manage Location" tenantName="UE" />
      <CustomAlert
        severity={
          alertMessage === "You have successfully closed your location" ||
          alertMessage === "A location is successfully updated" || 
          alertMessage === "You have successfully updated your location" || 
          alertMessage === "You have successfully deleted your location"
            ? "success"
            : null
        }
        color={
          alertMessage === "You have successfully closed your location" ||
          alertMessage === "A location is successfully updated" ||
          alertMessage === "You have successfully updated your location" ||
          alertMessage === "You have successfully deleted your location"
            ? "success"
            : null
        }
        alertOpen={!!alertMessage}
        setAlertOpen={() => setAlertMessage(null)}
        alertMessage={alertMessage}
      />
      {loading ? null : (
        <Box>
          {error ? (
            <Box
              display="flex"
              justifyContent="center"
              style={{
                position: "absolute",
                top: "50%",
                left: "40%",
                fontWeight: "bold",
                fontSize: "20px"
              }}
            >
              {error === "timeout"
                ? "Cannot contact Server. Please try again later."
                : error === "404"
                ? "Requested location not found."
                : error === "4xx"
                ? "Bad Request."
                : error === "5xx"
                ? "Server Error."
                : error === "non-200"
                ? ""
                : ""}
            </Box>
          ) : (
            <Box>
              {showClosure ? (
                <ClosureModal
                  open={showClosure}
                  closeHandler={() => setShowClosure(false)}
                  location={{
                    ...closureLocation,
                    state: stateArray.find(state =>
                      state.state.startsWith(closureLocation.state)
                    ).state
                  }}
                  closureStep={"getClosureInfo"}
                  setAlertMessage={setAlertMessage}
                  mode={closureModalMode}
                />
              ) : null}
              {showCancelClosureModal ? (
                <CancelClosureModal
                  open={showCancelClosureModal}
                  closeHandler={() => setShowCancelClosureModal(false)}
                  location={{
                    ...closureLocation,
                    state: stateArray.find(state =>
                      state.state.startsWith(closureLocation.state)
                    ).state
                  }}
                  setAlertMessage={setAlertMessage}
                  mode={closureModalMode}
                />
              ) : null}
              <Divider orientation="horizontal" />

              <Box className={classes.sectionContainer}>
                <Typography variant="h3" className={classes.sectionHeader}>
                  General Information
                </Typography>
                <Box display="flex" flexDirection="row">
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexBasis="50%"
                    className={classes.sectionColumn}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      className={classes.inputRow}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexBasis="50%"
                        className={classes.inputColumn}
                      >
                        <Box id="reviewLocationId">
                          <Typography
                            className={classes.itemHeader}
                            variant="h5"
                          >
                            ECR (Enrollment Center Roster):
                          </Typography>
                          <Box>
                            <Typography
                              className={classes.item}
                              variant="body1"
                            >
                              {common.locationId}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexBasis="50%"
                        className={classes.inputColumn}
                      >
                        <TextField
                          required
                          style={{ width: "100%" }}
                          size="small"
                          label="Location name"
                          variant="outlined"
                          name="locationName"
                          value={common.locationName}
                          onChange={textFieldHandler}
                          inputProps={{ maxLength: 50 }}
                          error={
                            !FormValidation.locationName(common.locationName) &&
                            dirtyState.locationName
                          }
                          helperText={WarningError.locationName(
                            !FormValidation.locationName(common.locationName) &&
                              dirtyState.locationName
                          )}
                          id="locationName"
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      className={classes.inputRow}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexBasis="50%"
                        className={classes.inputColumn}
                      >
                        <Box id="reviewLocationId">
                          <Typography
                            className={classes.itemHeader}
                            variant="h5"
                          >
                            Sales Tax Rate:
                          </Typography>
                          <Box>
                            <Typography
                              className={classes.item}
                              variant="body1"
                            >
                              {common === "undefined"
                                ? ""
                                : common.salesTaxRate * 100 + "%"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexBasis="50%"
                        className={classes.inputColumn}
                      >
                        <TextField
                          style={{ width: "100%" }}
                          size="small"
                          label="BSSR ID (Business Services Site Reference)"
                          variant="outlined"
                          inputProps={{ maxLength: 20 }}
                          name="bssrid"
                          value={common.bssrid}
                          onChange={textFieldHandler}
                          id="bssrId"
                          error={
                            !FormValidation.bssrid(common.bssrid) &&
                            dirtyState.bssrid
                          }
                          helperText={WarningError.bssrid(
                            !FormValidation.bssrid(common.bssrid) &&
                              dirtyState.bssrid
                          )}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexBasis="50%"
                    className={classes.sectionColumn}
                  >
                    <TextField
                      rows={4}
                      multiline={true}
                      inputProps={{ maxLength: 255 }}
                      label="More details"
                      className={`${classes.moreDetailInput}`}
                      variant="outlined"
                      name="description"
                      value={common.description}
                      onChange={textFieldHandler}
                      id="moreDetails"
                      error={
                        !FormValidation.description(common.description) &&
                        dirtyState.description
                      }
                      helperText={WarningError.description(
                        !FormValidation.description(common.description) &&
                          dirtyState.description
                      )}
                    />
                  </Box>
                </Box>
              </Box>

              <Divider orientation="horizontal" style={{ marginTop: "10px" }} />

              <TabContext value={selectedTab}>
                <Box display={"flex"}>
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                  >
                    {locationObj.currentVersion ? (
                      <Tab label="Current" value="current" id="currentButton" />
                    ) : null}
                    {locationObj.futureVersion &&
                    !locationObj.futureVersion.closed ? (
                      <Tab label="Future" value="future" id="futureButton" />
                    ) : null}
                    {locationObj.futureVersion &&
                    locationObj.futureVersion.closed ? (
                      <Tab label="Closure" value="closure" id="closureButton" />
                    ) : null}
                  </Tabs>
                  {locationObj.futureVersion &&
                  locationObj.futureVersion.closed ? (
                    <NavButtons
                      buttonContainerClassName={
                        classes.locationManagementButtons
                      }
                      handleLeftButtonClick={() => {
                        setClosureModalMode("edit");
                        setShowClosure(true);
                      }}
                      leftButtonId={"editLocationClosureButton"}
                      leftButtonDisabled={false}
                      leftButtonText={"Edit Location Closure"}
                      hideLeftButton={false}
                      leftButtonColor={"secondary"}
                      leftButtonClassName={classes.closeLocationButton}
                      handleRightButtonClick={() =>
                        setShowCancelClosureModal(true)
                      }
                      rightButtonId={"cancelClosure"}
                      rightButtonDisabled={false}
                      rightButtonText={"Cancel Closure"}
                      hideRightButton={false}
                      rightButtonClassName={classes.addFutureVersionButton}
                    />
                  ) : (
                    <NavButtons
                      buttonContainerClassName={
                        classes.locationManagementButtons
                      }
                      handleLeftButtonClick={() => {
                        setShowClosure(true);
                      }}
                      leftButtonId={"closeLocationButton"}
                      leftButtonDisabled={false}
                      leftButtonText={"Close Location"}
                      hideLeftButton={!locationObj.currentVersion}
                      leftButtonColor={"secondary"}
                      leftButtonClassName={classes.closeLocationButton}
                      handleRightButtonClick={
                        locationObj.futureVersion
                          ? editFutureVersionHandler
                          : addFutureVersionHandler
                      }
                      rightButtonId={
                        locationObj.futureVersion
                          ? "editFutureVersionButton"
                          : "addFutureVersionButton"
                      }
                      rightButtonDisabled={false}
                      rightButtonText={
                        locationObj.futureVersion
                          ? "Edit Future Version"
                          : "Add Future Version"
                      }
                      hideRightButton={
                        locationObj.futureVersion &&
                        locationObj.futureVersion.closed
                      }
                      rightButtonClassName={classes.addFutureVersionButton}
                    />
                  )}
                </Box>
                {locationObj.currentVersion ? (
                  <TabPanel value="current">
                    <ReviewContent
                      locationInfo={getInfoFromLocationVersion(
                        locationObj.currentVersion
                      )}
                      hoursOfOperation={
                        locationObj.currentVersion.hoursOfOperation
                      }
                    />
                  </TabPanel>
                ) : null}
                {locationObj.futureVersion &&
                !locationObj.futureVersion.closed ? (
                  <TabPanel value="future">
                    <ReviewContent
                      locationInfo={getInfoFromLocationVersion(
                        locationObj.futureVersion
                      )}
                      hoursOfOperation={
                        locationObj.futureVersion.hoursOfOperation
                      }
                    />
                  </TabPanel>
                ) : null}
                {locationObj.futureVersion &&
                locationObj.futureVersion.closed ? (
                  <TabPanel value="closure">
                    <ClosureReviewContent
                      locationInfo={getInfoFromLocationVersion(
                        locationObj.futureVersion
                      )}
                    />
                  </TabPanel>
                ) : null}
              </TabContext>

              <NavButtons
                hideLeftButton={false}
                leftButtonText={"Return to Homepage"}
                leftButtonColor={"secondary"}
                leftButtonClassName={classes.returnToHomepageButton}
                handleLeftButtonClick={() => {
                  history.push("/");
                }}
                leftButtonId={"returnToHomepageButton"}
                handleRightButtonClick={handleCommonSave}
                rightButtonId={"saveButton"}
                rightButtonDisabled={!isFormValid(true, common, dirtyState)}
                rightButtonText={"Save"}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default EditLocationHome;
