import { React, useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import WarningError from "../WarningError";
import FormValidation from "../FormValidation";
import moment from "moment";
import { useOktaAuth } from "@okta/okta-react";
import { getDomain } from "../Domain";
import { responseHandler } from "../utilities/HttpResponseHandler";
import { stateArray } from "../stateArray";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormControlLabel } from "@material-ui/core";
import { populateLatLong } from "../utilities/SmartyStreets";
import CustomSelect from "../components/CustomSelect";
import SpecialLocationAccessCodeInput from "../components/SpecialLocationAccessCodeInput";
import CustomDivider from "../components/CustomDivider";
import CustomCheckbox from "../components/CustomCheckbox";
import NavButtons from "../components/NavButtons";
import CustomDialog from "../components/CustomDialog";

const useStyles = makeStyles(theme => ({
  sectionContainer: {
    marginBottom: "15px",
    "&:last-child": {
      marginBottom: "0"
    }
  },
  sectionHeader: {
    marginBottom: "15px"
  },
  sectionColumn: {
    marginRight: "45px",
    "&:last-child": {
      marginRight: "0"
    }
  },
  inputRow: {
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0"
    }
  },
  inputColumn: {
    marginRight: "11px",
    "&:last-child": {
      marginRight: "0"
    }
  },
  formControl: {
    width: "100%"
  },
  moreDetailInput: {
    width: "100%",
    height: "calc(100% - 16px)",
    "&>div": {
      height: "100%"
    }
  }
}));

const LocationInfo = props => {
  const locationNameInputRef = useRef();
  const effectiveDateInputRef = useRef();
  const bssridInputRef = useRef();
  const buildingInputRef = useRef();
  const longitudeInputRef = useRef();
  const postalCodeInputRef = useRef();
  const cityInputRef = useRef();
  const latitudeInputRef = useRef();
  const partnerNameInputRef = useRef();
  const hostNameInputRef = useRef();
  const airportCodeInputRef = useRef();

  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  const [locationTypeArray, setLocationTypeArray] = useState([]);
  const [
    specialLocationAccessCodeArray,
    setspecialLocationAccessCodeArray
  ] = useState([]);
  const [partnerTypeArray, setPartnerTypeArray] = useState([]);
  const [timezoneArray, setTimezoneArray] = useState([]);
  const [partnerNameArray, setPartnerNameArray] = useState([]);

  const [locationInfoObj, setLocationInfoObject] = useState(props.locationObj);

  const [dirtyState, setDirtyState] = useState({
    locationId: false,
    locationName: false,
    bssrid: false,
    effectiveDate: false,
    building: false,
    addressLine1: false,
    addressLine2: false,
    city: false,
    state: false,
    postalCode: false,
    longitude: false,
    latitude: false,
    description: false,
    locationType: false,
    partnerType: false,
    hostName: false,
    partnerName: false,
    partnerLocationId: false,
    airportCode: false,
    timezone: false,
    specialLocationFee: false,
    specialLocationAccessCode: false
  });

  const [loading, setLoading] = useState(false);

  let currentTime = moment(new Date()).format("YYYY-MM-DD");
  let oneDayAfterTime = moment(new Date())
    .add(1, "days")
    .format("YYYY-MM-DD");

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();
    setLoading(true);
    fetch(`${getDomain()}tenants/UE/enumerations`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        return responseHandler(response);
      })
      .then(object => {
        setLocationTypeArray(object.locationTypes);
        setPartnerTypeArray(object.partnerTypes);
        setTimezoneArray(object.timezones);
        setPartnerNameArray(object.partners);
        setspecialLocationAccessCodeArray(object.specialLocationAccessCodes);
        setLoading(false);
      })
      .catch(e => {
        setLocationTypeArray([]);
        setPartnerTypeArray([]);
        setTimezoneArray([]);
        setPartnerNameArray([]);
        setspecialLocationAccessCodeArray([]);
        props.setAlertMessage("Cannot retrieve suggestions.");
        setLoading(false);
      });
  }, []);

  const handleNext = () => {
    if (props.isFormValid(true, locationInfoObj, dirtyState)) {
      props.handleNext(locationInfoObj);
    }
  };

  const specialLocationAccessCodeHandler = value => {
    setLocationInfoObject({
      ...locationInfoObj,
      specialLocationAccessCode: value
    });
    setDirtyState({ ...dirtyState, specialLocationAccessCode: true });
  };

  const inputChangeHandler = event => {
    let value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setLocationInfoObject({
      ...locationInfoObj,
      [event.target.name]: value
    });
    setDirtyState({ ...dirtyState, [event.target.name]: true });
  };

  const partnerNameAutoCompleteHandler = event => {
    setLocationInfoObject({
      ...locationInfoObj,
      partnerName: event.target.textContent
    });
    setDirtyState({ ...dirtyState, partnerName: true });
  };

  return (
    <Box>
      <CustomDialog
        open={loading}
        header={"Loading....."}
        showCloseX={false}
        content={
          <CircularProgress size={36} className={classes.buttonProgress} />
        }
      />
      <Typography variant="h2">Location Information</Typography>
      <CustomDivider />
      <Box className={classes.sectionContainer}>
        <Typography variant="h3" className={classes.sectionHeader}>
          General Information
        </Typography>
        <Box display="flex" flexDirection="row">
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="50%"
            className={classes.sectionColumn}
          >
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  variant="outlined"
                  label="Effective Date"
                  type="date"
                  value={locationInfoObj.effectiveDate}
                  id="effectiveDate"
                  name="effectiveDate"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    min:
                      props.mode === "editLocation"
                        ? oneDayAfterTime
                        : currentTime
                  }}
                  onChange={inputChangeHandler}
                  required
                  error={
                    (!FormValidation.effectiveDate(
                      locationInfoObj.effectiveDate,
                      locationInfoObj.timezone,
                      props.mode
                    ) &&
                      locationInfoObj.effectiveDate) ||
                    locationInfoObj.effectiveDate === ""
                  }
                  helperText={WarningError.effectiveDate(
                    (!FormValidation.effectiveDate(
                      locationInfoObj.effectiveDate,
                      locationInfoObj.timezone,
                      props.mode
                    ) &&
                      locationInfoObj.effectiveDate) ||
                      locationInfoObj.effectiveDate === ""
                  )}
                  inputRef={effectiveDateInputRef}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                {!!props.locationIdEditable ? (
                  <TextField
                    required
                    style={{ width: "100%" }}
                    id="locationId"
                    size="small"
                    name="locationId"
                    label="ECR (Enrollment Center Roster)"
                    variant="outlined"
                    value={locationInfoObj.locationId}
                    onChange={inputChangeHandler}
                    inputProps={{ maxLength: 50 }}
                    error={
                      !FormValidation.locationId(locationInfoObj.locationId) &&
                      dirtyState.locationId
                    }
                    helperText={WarningError.locationId(
                      !FormValidation.locationId(locationInfoObj.locationId) &&
                        dirtyState.locationId
                    )}
                  />
                ) : (
                  <Box id="reviewLocationId">
                    <strong>ECR (Enrollment Center Roster):</strong>
                    <Box>{locationInfoObj.locationId}</Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                {!!props.locationNameEditable ? (
                  <TextField
                    required
                    style={{ width: "100%" }}
                    size="small"
                    label="Location Name"
                    variant="outlined"
                    name="locationName"
                    value={locationInfoObj.locationName}
                    onChange={inputChangeHandler}
                    inputProps={{ maxLength: 50 }}
                    error={
                      !FormValidation.locationName(
                        locationInfoObj.locationName
                      ) && dirtyState.locationName
                    }
                    helperText={WarningError.locationName(
                      !FormValidation.locationName(
                        locationInfoObj.locationName
                      ) && dirtyState.locationName
                    )}
                    id="locationName"
                    inputRef={locationNameInputRef}
                  />
                ) : (
                  <Box id="reviewLocationName">
                    <strong>Location Name: </strong>
                    <Box>{locationInfoObj.locationName}</Box>
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <CustomSelect
                  label={"Location Type"}
                  required
                  classes={classes}
                  value={locationInfoObj.locationType}
                  onChange={inputChangeHandler}
                  options={locationTypeArray.map(locationType => {
                    return {
                      value: locationType.locationType,
                      name: locationType.locationType
                    };
                  })}
                  labelId={"demo-simple-select-placeholder-label-label"}
                  selectId={"locationType"}
                  selectName={"locationType"}
                  optionIdPrefix={"locationType"}
                  helperTextId={"locationType-helper-text"}
                  helperMessage={"Please Select Location Type"}
                  goBackOneInput={() => {
                    !!props.locationNameEditable
                      ? setTimeout(
                          () => locationNameInputRef.current.focus(),
                          100
                        )
                      : setTimeout(
                          () => effectiveDateInputRef.current.focus(),
                          100
                        );
                  }}
                  goForwardOneInput={() => {
                    !!props.bssridEditable
                      ? setTimeout(() => bssridInputRef.current.focus(), 100)
                      : setTimeout(() => buildingInputRef.current.focus(), 100);
                  }}
                  error={
                    !FormValidation.locationType(
                      locationInfoObj.locationType
                    ) && dirtyState.locationType
                  }
                  helperText={WarningError.locationType(
                    !FormValidation.locationType(
                      locationInfoObj.locationType
                    ) && dirtyState.locationType
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                {!!props.bssridEditable ? (
                  <TextField
                    style={{ width: "100%" }}
                    size="small"
                    label="BSSR ID (Business Services Site Reference)"
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                    name="bssrid"
                    value={locationInfoObj.bssrid}
                    onChange={inputChangeHandler}
                    id="bssrId"
                    error={
                      !FormValidation.bssrid(locationInfoObj.bssrid) &&
                      dirtyState.bssrid
                    }
                    helperText={WarningError.bssrid(
                      !FormValidation.bssrid(locationInfoObj.bssrid) &&
                        dirtyState.bssrid
                    )}
                    inputRef={bssridInputRef}
                  />
                ) : (
                  <Box id="reviewBssrId">
                    <strong>BSSR ID: </strong>
                    <Box>{locationInfoObj.bssrid}</Box>
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flexBasis="50%">
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              {!!props.descriptionEditable ? (
                <TextField
                  rows={4}
                  multiline={true}
                  inputProps={{ maxLength: 255 }}
                  label="More Details"
                  className={`${classes.moreDetailInput}`}
                  variant="outlined"
                  name="description"
                  value={locationInfoObj.description}
                  onChange={inputChangeHandler}
                  id="moreDetails"
                  error={
                    !FormValidation.description(locationInfoObj.description) &&
                    dirtyState.description
                  }
                  helperText={WarningError.description(
                    !FormValidation.description(locationInfoObj.description) &&
                      dirtyState.description
                  )}
                />
              ) : (
                <Box>
                  <strong>More Details: </strong>{" "}
                  <Box>
                    <Typography
                      style={{ wordWrap: "break-word" }}
                      id="reviewMoreDetails"
                    >
                      {locationInfoObj.description
                        ? locationInfoObj.description
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.sectionContainer}>
        <Box display="flex" flexDirection="row">
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="50%"
            className={classes.sectionColumn}
          >
            <Typography variant="h3" className={classes.sectionHeader}>
              Address Information
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  label="Building"
                  variant="outlined"
                  inputProps={{ maxLength: 75 }}
                  name="building"
                  value={locationInfoObj.building}
                  onChange={inputChangeHandler}
                  id="buildingName"
                  required
                  error={
                    !FormValidation.building(locationInfoObj.building) &&
                    dirtyState.building
                  }
                  helperText={WarningError.building(
                    !FormValidation.building(locationInfoObj.building) &&
                      dirtyState.building
                  )}
                  inputRef={buildingInputRef}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <TextField
                  label={"Address 1"}
                  required
                  style={{ width: "100%" }}
                  size="small"
                  className={`${classes.field}`}
                  variant="outlined"
                  inputProps={{ maxLength: 75 }}
                  name="addressLine1"
                  value={locationInfoObj.addressLine1}
                  onChange={inputChangeHandler}
                  error={
                    !FormValidation.addressLine1(
                      locationInfoObj.addressLine1
                    ) && dirtyState.addressLine1
                  }
                  helperText={WarningError.addressLine1(
                    !FormValidation.addressLine1(
                      locationInfoObj.addressLine1
                    ) && dirtyState.addressLine1
                  )}
                  id="addressLine1"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="100%"
                className={classes.inputColumn}
              >
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  label="Address 2"
                  variant="outlined"
                  inputProps={{ maxLength: 75 }}
                  name="addressLine2"
                  value={locationInfoObj.addressLine2}
                  onChange={inputChangeHandler}
                  error={
                    !FormValidation.addressLine2(
                      locationInfoObj.addressLine2
                    ) && dirtyState.addressLine2
                  }
                  helperText={WarningError.addressLine2(
                    !FormValidation.addressLine2(
                      locationInfoObj.addressLine2
                    ) && dirtyState.addressLine2
                  )}
                  id="addressLine2"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="33.33%"
                className={classes.inputColumn}
              >
                <TextField
                  required
                  style={{ width: "100%" }}
                  size="small"
                  label="City"
                  variant="outlined"
                  inputProps={{ maxLength: 35 }}
                  name="city"
                  value={locationInfoObj.city}
                  onChange={inputChangeHandler}
                  error={
                    !FormValidation.city(locationInfoObj.city) &&
                    dirtyState.city
                  }
                  helperText={WarningError.city(
                    !FormValidation.city(locationInfoObj.city) &&
                      dirtyState.city
                  )}
                  id="city"
                  inputRef={cityInputRef}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="33.33%"
                className={classes.inputColumn}
              >
                <CustomSelect
                  label={"State"}
                  required
                  classes={classes}
                  error={
                    !FormValidation.state(locationInfoObj.state) &&
                    dirtyState.state
                  }
                  value={locationInfoObj.state}
                  onChange={inputChangeHandler}
                  options={stateArray.map(state => {
                    return { value: state.state, name: state.state };
                  })}
                  selectId={"state"}
                  selectName={"state"}
                  optionIdPrefix={"state"}
                  helperTextId={"state-helper-text"}
                  helperMessage={WarningError.state(
                    !FormValidation.state(locationInfoObj.state) &&
                      dirtyState.state
                  )}
                  goBackOneInput={() => {
                    setTimeout(() => cityInputRef.current.focus(), 100);
                  }}
                  goForwardOneInput={() => {
                    setTimeout(() => postalCodeInputRef.current.focus(), 100);
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="33.33%"
                className={classes.inputColumn}
              >
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  label="Postal Code"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  name="postalCode"
                  value={locationInfoObj.postalCode}
                  onChange={inputChangeHandler}
                  id="postalCode"
                  required
                  error={
                    !FormValidation.postalCode(locationInfoObj.postalCode) &&
                    dirtyState.postalCode
                  }
                  inputRef={postalCodeInputRef}
                  helperText={WarningError.postalCode(
                    !FormValidation.postalCode(locationInfoObj.postalCode) &&
                      dirtyState.postalCode
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  label="Airport Code"
                  id="airportCode"
                  variant="outlined"
                  inputProps={{ maxLength: 4 }}
                  name="airportCode"
                  value={locationInfoObj.airportCode}
                  onChange={inputChangeHandler}
                  inputRef={airportCodeInputRef}
                  error={
                    !FormValidation.airportCode(locationInfoObj.airportCode) &&
                    dirtyState.airportCode
                  }
                  helperText={WarningError.airportCode(
                    !FormValidation.airportCode(locationInfoObj.airportCode) &&
                      dirtyState.airportCode
                  )}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <CustomSelect
                  label={"Timezone"}
                  required
                  classes={classes}
                  value={locationInfoObj.timezone}
                  onChange={inputChangeHandler}
                  options={timezoneArray.map(timezone => {
                    return {
                      value: timezone.timezone,
                      name: timezone.timezone
                    };
                  })}
                  selectId={"timezone"}
                  selectName={"timezone"}
                  optionIdPrefix={"timezone"}
                  helperTextId={"timezone-helper-text"}
                  helperMessage={WarningError.timezone(
                    !FormValidation.timezone(locationInfoObj.timezone) &&
                      dirtyState.timezone
                  )}
                  goBackOneInput={() => {
                    setTimeout(() => airportCodeInputRef.current.focus(), 100);
                  }}
                  goForwardOneInput={() => {
                    setTimeout(() => longitudeInputRef.current.focus(), 100);
                  }}
                  error={
                    !FormValidation.timezone(locationInfoObj.timezone) &&
                    dirtyState.timezone
                  }
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="30%"
                className={classes.inputColumn}
              >
                <TextField
                  required
                  style={{ width: "100%" }}
                  size="small"
                  label="Longitude"
                  variant="outlined"
                  name="longitude"
                  value={
                    locationInfoObj.longitude ? locationInfoObj.longitude : ""
                  }
                  onChange={inputChangeHandler}
                  error={
                    !FormValidation.longitude(locationInfoObj.longitude) &&
                    dirtyState.longitude
                  }
                  id="long"
                  helperText={WarningError.longitude(
                    !FormValidation.longitude(locationInfoObj.longitude) &&
                      dirtyState.longitude
                  )}
                  inputRef={longitudeInputRef}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="30%"
                className={classes.inputColumn}
              >
                <TextField
                  required
                  style={{ width: "100%" }}
                  size="small"
                  label="Latitude"
                  variant="outlined"
                  name="latitude"
                  value={
                    locationInfoObj.latitude ? locationInfoObj.latitude : ""
                  }
                  onChange={inputChangeHandler}
                  error={
                    !FormValidation.latitude(locationInfoObj.latitude) &&
                    dirtyState.latitude
                  }
                  helperText={WarningError.latitude(
                    !FormValidation.latitude(locationInfoObj.latitude) &&
                      dirtyState.latitude
                  )}
                  id="lat"
                  inputRef={latitudeInputRef}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="40%"
                className={classes.inputColumn}
              >
                <Box display="flex" flexDirection="row">
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexBasis="50%"
                    className={classes.inputColumn}
                  >
                    <Button
                      variant="contained"
                      color="Primary"
                      id="getCoordinatesButton"
                      onClick={() =>
                        populateLatLong(
                          locationInfoObj,
                          setLocationInfoObject,
                          props.setAlertMessage
                        )
                      }
                    >
                      Get Coordinates
                    </Button>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexBasis="50%"
                    className={classes.inputColumn}
                  >
                    <Button
                      variant="contained"
                      color="Primary"
                      id="viewMapButton"
                    >
                      View Map
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="50%"
            className={classes.sectionColumn}
          >
            <Typography variant="h3" className={classes.sectionHeader}>
              Partner Information
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis={
                  locationInfoObj.partnerType === "Host" ? "50%" : "100%"
                }
                className={classes.inputColumn}
              >
                <CustomSelect
                  label={"Partner Type"}
                  required
                  classes={classes}
                  value={locationInfoObj.partnerType}
                  onChange={inputChangeHandler}
                  options={partnerTypeArray.map(partnerType => {
                    return {
                      value: partnerType.partnerType,
                      name: partnerType.partnerType
                    };
                  })}
                  selectId={"partnerType"}
                  selectName={"partnerType"}
                  optionIdPrefix={"partnerType"}
                  helperTextId={"partnerType-helper-text"}
                  helperMessage={"Please Select Partner Type"}
                  goBackOneInput={() => {
                    setTimeout(() => latitudeInputRef.current.focus(), 100);
                  }}
                  goForwardOneInput={() => {
                    setTimeout(
                      () =>
                        !!hostNameInputRef.current
                          ? hostNameInputRef.current.focus()
                          : partnerNameInputRef.current.focus(),
                      100
                    );
                  }}
                  error={
                    !FormValidation.partnerType(locationInfoObj.partnerType) &&
                    dirtyState.partnerType
                  }
                  helperText={WarningError.partnerType(
                    !FormValidation.partnerType(locationInfoObj.partnerType) &&
                      dirtyState.partnerType
                  )}
                />
              </Box>
              {locationInfoObj.partnerType === "Host" ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  flexBasis="50%"
                  className={classes.inputColumn}
                >
                  <TextField
                    required
                    size="small"
                    label="Host Name"
                    variant="outlined"
                    name="hostName"
                    value={locationInfoObj.hostName}
                    onChange={inputChangeHandler}
                    id="hostname"
                    inputProps={{ maxLength: 50 }}
                    style={{ width: "100%" }}
                    inputRef={hostNameInputRef}
                    error={
                      !FormValidation.hostName(
                        locationInfoObj.partnerType,
                        locationInfoObj.hostName
                      ) && dirtyState.hostName
                    }
                    helperText={WarningError.hostName(
                      !FormValidation.hostName(
                        locationInfoObj.partnerType,
                        locationInfoObj.hostName
                      ) && dirtyState.hostName
                    )}
                  />
                </Box>
              ) : null}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <Autocomplete
                  id="partnerNameAutoComplete"
                  freeSolo
                  options={partnerNameArray
                    .filter(item => {
                      if (!locationInfoObj.partnerName) {
                        return true;
                      } else {
                        return item.partner.startsWith(
                          locationInfoObj.partnerName
                        );
                      }
                    })
                    .map(item => item.partner)}
                  inputProps={{ maxLength: 50 }}
                  value={locationInfoObj.partnerName}
                  onChange={partnerNameAutoCompleteHandler}
                  label="Partner Name"
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      required
                      id="partnerName"
                      name="partnerName"
                      onChange={inputChangeHandler}
                      label="Partner Name"
                      inputRef={partnerNameInputRef}
                      error={
                        !FormValidation.partnerName(
                          locationInfoObj.partnerName
                        ) && dirtyState.partnerName
                      }
                      helperText={WarningError.partnerName(
                        !FormValidation.partnerName(
                          locationInfoObj.partnerName
                        ) && dirtyState.partnerName
                      )}
                    />
                  )}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  label="Partner Location Id"
                  variant="outlined"
                  id="partnerLocationId"
                  name="partnerLocationId"
                  value={locationInfoObj.partnerLocationId}
                  onChange={inputChangeHandler}
                  error={
                    !FormValidation.partnerLocationId(
                      locationInfoObj.partnerLocationId
                    ) && dirtyState.partnerLocationId
                  }
                  helperText={WarningError.partnerLocationId(
                    !FormValidation.partnerLocationId(
                      locationInfoObj.partnerLocationId
                    ) && dirtyState.partnerLocationId
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Box display="flex" flexDirection="row">
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="50%"
            className={classes.sectionColumn}
          >
            <Typography variant="h3" className={classes.sectionHeader}>
              Service Availability
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box display="flex" flexDirection="column" flexBasis="25%">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="appointmentCheckbox"
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={inputChangeHandler}
                      name="appointments"
                      checked={locationInfoObj.appointments}
                    />
                  }
                  label="Appointments"
                />
              </Box>
              <Box display="flex" flexDirection="column" flexBasis="25%">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="samedayAppointmentCheckbox"
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={inputChangeHandler}
                      name="sameDayAppointments"
                      checked={locationInfoObj.sameDayAppointments}
                    />
                  }
                  label="Same-Day Appointments"
                />
              </Box>
              <Box display="flex" flexDirection="column" flexBasis="25%">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="oneVisitEligibleCheckbox"
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={inputChangeHandler}
                      name="oneVisitEligible"
                      checked={locationInfoObj.oneVisitEligible}
                    />
                  }
                  label="OneVisit Eligible"
                />
              </Box>
              <Box display="flex" flexDirection="column" flexBasis="25%">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="walkInsCheckbox"
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onChange={inputChangeHandler}
                      name="walkins"
                      checked={locationInfoObj.walkins}
                    />
                  }
                  label="Walkins"
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="50%"
            className={classes.sectionColumn}
          >
            <Typography variant="h3" className={classes.sectionHeader}>
              Special Location Information
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.inputRow}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <TextField
                  id="specialLocationFee"
                  style={{ width: "100%" }}
                  size="small"
                  label="Special Location Fee"
                  variant="outlined"
                  name="specialLocationFee"
                  value={locationInfoObj.specialLocationFee}
                  onChange={inputChangeHandler}
                  error={
                    !FormValidation.specialLocationFee(
                      locationInfoObj.specialLocationFee
                    ) && dirtyState.specialLocationFee
                  }
                  helperText={WarningError.specialLocationFee(
                    !FormValidation.specialLocationFee(
                      locationInfoObj.specialLocationFee
                    ) && dirtyState.specialLocationFee
                  )}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flexBasis="50%"
                className={classes.inputColumn}
              >
                <SpecialLocationAccessCodeInput
                  options={specialLocationAccessCodeArray
                    .filter(item => {
                      if (!locationInfoObj.specialLocationAccessCode) {
                        return true;
                      } else {
                        return item.specialLocationAccessCode
                          .replace("SP-", "")
                          .startsWith(
                            locationInfoObj.specialLocationAccessCode.replace(
                              "SP-",
                              ""
                            )
                          );
                      }
                    })
                    .map(item => item.specialLocationAccessCode)}
                  error={
                    !FormValidation.specialLocationAccessCode(
                      locationInfoObj.specialLocationAccessCode
                    ) && dirtyState.specialLocationAccessCode
                  }
                  helperText={WarningError.specialLocationAccessCode(
                    !FormValidation.specialLocationAccessCode(
                      locationInfoObj.specialLocationAccessCode
                    ) && dirtyState.specialLocationAccessCode
                  )}
                  valueChange={specialLocationAccessCodeHandler}
                  value={locationInfoObj.specialLocationAccessCode}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <NavButtons
        hideLeftButton={true}
        handleRightButtonClick={handleNext}
        rightButtonId={"nextButton"}
        rightButtonDisabled={
          !props.isFormValid(true, locationInfoObj, dirtyState)
        }
        rightButtonText={"Next"}
      />
    </Box>
  );
};

export default LocationInfo;
