import { React, useState } from "react";
import CustomDialog from "../components/CustomDialog";
import ClosureForm from "./ClosureForm.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { verifyAddress } from "../utilities/SmartyStreets";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ClosureVerifyAddress from "./ClosureVerifyAddress";
import FormValidation from "../FormValidation";
import { stateArray } from "../stateArray";
import { getDomain } from "../Domain";
import { useOktaAuth } from "@okta/okta-react";
import { responseHandler } from "../utilities/HttpResponseHandler";
import { messages } from "../Constants/Message";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ClosureModal(props) {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();

  const [closureStep, setClosureStep] = useState(
    props.closureStep ? props.closureStep : "getClosureInfo"
  );
  const [location, setLocation] = useState({
    ...props.location,
    effectiveDate: props.mode === "edit" ? props.location.effectiveDate : null,
    description: props.mode === "edit" ? props.location.description : null,
  });
  const [verificationOptions, setVerificationOptions] = useState([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);

  const locationIsValid = (location) => {
    return (
      FormValidation.effectiveDate(
        location.effectiveDate,
        location.timezone,
        "addLocation"
      ) &&
      FormValidation.closureReason(location.description) &&
      FormValidation.addressLine1(location.addressLine1) &&
      FormValidation.addressLine2(location.addressLine2) &&
      FormValidation.city(location.city) &&
      FormValidation.state(location.state) &&
      FormValidation.postalCode(location.postalCode)
    );
  };

  const verifiedAddressMatchesExactly = (smartyStreetAddress) => {
    return (
      smartyStreetAddress.delivery_line_1 === location.addressLine1 &&
      smartyStreetAddress.components.city_name === location.city &&
      smartyStreetAddress.components.state_abbreviation ===
        location.state.substring(0, 2) &&
      smartyStreetAddress.components.zipcode +
        "-" +
        smartyStreetAddress.components.plus4_code ===
        location.postalCode
    );
  };

  const saveClosureInfoHandler = () => {
    setClosureStep("loading");

    if (
      props.mode === "edit" &&
      location.addressLine1 === props.location.addressLine1 &&
      location.addressLine2 === props.location.addressLine2 &&
      location.city === props.location.city &&
      location.state === props.location.state &&
      location.postalCode === props.location.postalCode
    ) {
      setClosureStep("addressExactMatch");
    } else {
      verifyAddress(location)
        .then((geoInfo) => {
          if (
            geoInfo.length === 1 &&
            verifiedAddressMatchesExactly(geoInfo[0])
          ) {
            setClosureStep("addressExactMatch");
          } else {
            setVerificationOptions(geoInfo);
            setClosureStep("verifyAddress");
          }
        })
        .catch((error) => {
          console.error(error);
          setClosureStep("unableToVerifyAddress");
        });
    }
  };

  const saveClosure = (closedLocation, closureMode) => {
    const accessToken = oktaAuth.getAccessToken();

    fetch(
      `${getDomain()}tenants/UE/location/${closedLocation.locationId}/close`,
      {
        method: "post",
        body: JSON.stringify({
          closureReason: closedLocation.description,
          addressLine1: closedLocation.addressLine1,
          addressLine2:
            closedLocation.addressLine2 === ""
              ? null
              : closedLocation.addressLine2,
          addressCity: closedLocation.city,
          addressStateProv: closedLocation.state.slice(0, 3),
          addressPostalCode: closedLocation.postalCode,
          effectiveDate: closedLocation.effectiveDate.substring(0, 10),
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        return responseHandler(response);
      })
      .then(() => {
        if (closureMode === 'edit') {
          window.location.href = window.location.pathname + "?state=edited";
        } else {
        window.location.href = window.location.pathname + "?state=canceled";
        }
      })
      .catch(() => {
        props.setAlertMessage(messages.serverError);
        props.closeHandler();
      });
  };

  const acceptUnverifiedAddress = (closureMode) => {
    setClosureStep("saving");
    saveClosure(location, closureMode);
  };

  const confirmedClosure = (closureMode) => {
    setClosureStep("saving");
    saveClosure(location, closureMode);
  };

  const getAddressLine1FromGeoAddress = (geoAddress) => {
    if (!!geoAddress.components.secondary_number) {
      return geoAddress.delivery_line_1.substring(
        0,
        geoAddress.delivery_line_1.indexOf(
          ` ${geoAddress.components.secondary_designator} ${geoAddress.components.secondary_number}`
        )
      );
    } else {
      return geoAddress.delivery_line_1;
    }
  };

  const acceptVerifiedAddress = (closureMode) => {
    setClosureStep("saving");
    if (selectedAddressIndex === "3") {
      saveClosure(location, closureMode);
    } else {
      saveClosure({
        ...location,
        addressLine1: getAddressLine1FromGeoAddress(
          verificationOptions[selectedAddressIndex]
        ),
        city: verificationOptions[selectedAddressIndex].components.city_name,
        state: stateArray.find(
          (state) =>
            verificationOptions[selectedAddressIndex].components
              .state_abbreviation === state.state.substring(0, 2)
        ).state,
        postalCode:
          verificationOptions[selectedAddressIndex].components.zipcode +
          "-" +
          verificationOptions[selectedAddressIndex].components.plus4_code,
      }, closureMode);
    }
  };

  const returnToInfoEntry = () => {
    setClosureStep("getClosureInfo");
  };

  const selectedAddressChanged = (event) => {
    setSelectedAddressIndex(event.target.value);
  };

  switch (closureStep) {
    case "getClosureInfo":
      return (
        <CustomDialog
          open={props.open}
          showCloseX={false}
          closeAction={null}
          header={
            props.mode === "edit" ? "Update Location Closure" : "Close Location"
          }
          content={
            <ClosureForm location={location} setLocation={setLocation} />
          }
          leftButtonText={"Save"}
          onLeftButtonClick={saveClosureInfoHandler}
          leftButtonDisabled={!locationIsValid(location)}
          rightButtonText={"Cancel"}
          onRightButtonClick={() => props.closeHandler()}
          id="closureModal"
        />
      );
    case "loading":
      return (
        <CustomDialog
          open={props.open}
          header={"Loading....."}
          showCloseX={false}
          content={
            <CircularProgress size={36} className={classes.buttonProgress} />
          }
        />
      );
    case "saving":
      return (
        <CustomDialog
          open={props.open}
          header={"Saving....."}
          showCloseX={false}
          content={
            <CircularProgress size={36} className={classes.buttonProgress} />
          }
        />
      );
    case "unableToVerifyAddress":
      return (
        <CustomDialog
          open={props.open}
          showCloseX={false}
          closeAction={null}
          header={
            props.mode === "edit" ? "Update Location Closure" : "Close Location"
          }
          content={
            <Box>
              <Typography variant="h3">Unable to Verify Address</Typography>
              <Typography variant="body1">
                If you would like to use the unverified address, press "Accept".
                Press "Cancel" to re-enter the address.
              </Typography>
            </Box>
          }
          leftButtonText={"Accept"}
          onLeftButtonClick={() => acceptUnverifiedAddress(props.mode)}
          rightButtonText={"Cancel"}
          onRightButtonClick={returnToInfoEntry}
          id="closureModal"
        />
      );
    case "verifyAddress":
      return (
        <CustomDialog
          open={props.open}
          showCloseX={false}
          closeAction={null}
          header={
            props.mode === "edit" ? "Update Location Closure" : "Close Location"
          }
          content={
            <ClosureVerifyAddress
              selectedAddress={selectedAddressIndex}
              selectedAddressChanged={selectedAddressChanged}
              verifiedAddresses={verificationOptions}
              enteredAddress={location}
            />
          }
          leftButtonText={"Accept"}
          onLeftButtonClick={() => acceptVerifiedAddress(props.mode)}
          leftButtonDisabled={selectedAddressIndex === null}
          rightButtonText={"Cancel"}
          onRightButtonClick={returnToInfoEntry}
          id="closureModal"
        />
      );
    case "addressExactMatch":
      return (
        <CustomDialog
          open={props.open}
          showCloseX={false}
          closeAction={null}
          header={
            props.mode === "edit" ? "Update Location Closure" : "Close Location"
          }
          content={
            <Typography variant="h3">
              {props.mode === "edit"
                ? "Are you sure you want to update the location closure?"
                : "Are you sure you want to close the location?"}
            </Typography>
          }
          leftButtonText={props.mode === "edit" ? "Yes" : "Close"}
          onLeftButtonClick={() => confirmedClosure(props.mode)}
          rightButtonText={"Cancel"}
          onRightButtonClick={returnToInfoEntry}
          id="closureModal"
        />
      );
  }
}
